/* App.css */

/* Import base styles */
@import './css/base.css';

/* App-specific global styles */
.App {
  min-height: 100vh;
  background-color: var(--background-main);
}

.main-content {
  padding: var(--spacing-xl) var(--spacing-lg);
  margin-top: 60px; /* Height of navbar */
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

/* Container utilities */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-xl);
}

.container-fluid {
  width: 100%;
  padding: var(--spacing-lg) var(--spacing-xl);
  max-width: 1440px;
  margin: 0 auto;
}

.container-narrow {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-lg) var(--spacing-xl);
}

/* Section spacing */
.section {
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-lg);
}

/* Card containers */
.card-container {
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

/* Form containers */
.form-container {
  padding: var(--spacing-xl);
  max-width: 600px;
  margin: 0 auto;
}

/* Loading states */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding: var(--spacing-xl);
}

/* Messages */
.error-message,
.success-message {
  padding: var(--spacing-lg);
  margin: var(--spacing-lg) 0;
  border-radius: var(--border-radius-sm);
}

.error-message {
  color: #dc3545;
  border: 1px solid #dc3545;
  background-color: #f8d7da;
}

.success-message {
  color: #28a745;
  border: 1px solid #28a745;
  background-color: #d4edda;
}

/* Prevent zooming when focusing on inputs */
input, select, textarea {
  -webkit-text-size-adjust: 100%; /* Prevent zooming on iOS */
  font-size: 16px; /* Ensure font size is large enough */
}

/* Additional style for mobile browsers */
@media (max-width: 768px) {
  body {
    touch-action: manipulation; /* Improves scrolling performance */
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .main-content {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .container,
  .container-fluid,
  .container-narrow {
    padding: var(--spacing-md);
  }

  .section {
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-lg);
  }
}

/* Copyright banner */
.copyrightBanner {
  padding: var(--spacing-md) var(--spacing-lg);
  text-align: center;
  background-color: var(--background-light);
  border-top: 1px solid var(--border-color);
  margin-top: var(--spacing-xl);
  position:relative;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.copyrightBanner a {
  color: #000000; /* Black links */
  text-decoration: none;
}

.copyrightBanner a:hover {
  text-decoration: underline;
}
