.container {
  padding: 40px 20px 20px; /* Top padding to avoid navigation bar, side padding */
  background-color: #e0e0e0; /* Gray background for the admin panel */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #17a2b8;
  font-size: 28px;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  border-collapse: separate; /* Separate borders for cells */
  border-spacing: 20px; /* Space between cells */
  max-width: 1200px;
}

.tableCell {
  width: 50%;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: white; /* White background for table cells */
  vertical-align: top;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
