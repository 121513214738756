.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  overflow: auto; /* Ensure modal itself can scroll */
}

.modal-content {
  background-color: #fefefe; /* White background */
  padding: 20px;
  max-width: 600px;
  max-height: 80%; /* Limit height to allow scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.invoice-page-total{
padding-top: 400px;
}

.close {
  color: #aaa;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  max-width: 900px;
  display: flex;
  margin-bottom: 2px;
}

.label {
  flex: 1;
  text-align: left;
  margin-right: 5px;
  font-weight: bold;
}

.input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.input[type="file"] {
  padding: 4px;
}

.input:disabled {
  color: #555;
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.input.manufacturer-input:not(:disabled) {
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: text;
}

.jurisdiction-options {
  display: flex;
  gap: 10px; /* Adjust spacing between pills */
}

.pill {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
}

input[type="radio"] {
  display: none; /* Hide the actual radio buttons */
}

input[type="radio"]:checked + .pill {
  background-color: #17a2b8; /* Change background color when selected */
  color: #fff; /* Change text color when selected */
  border-color: #17a2b8; /* Change border color when selected */
}

.add-order-button,
.submit-button {
  padding: 8px 16px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
}

.submit-button:disabled {
  background-color: #cccccc; /* Gray background */
  color: #666666; /* Dark gray text */
  cursor: not-allowed; /* Cursor not allowed */
}

.add-order-button:hover,
.submit-button:hover {
  background-color: #17a2b8;
}


/* CSS for the dropdown container */
.dropdown {
  position: relative;
  flex: 1;
  font-size: 14px;
}

/* CSS for the dropdown menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  z-index: 101; /* Ensure it appears above other content */
}

/* CSS for each dropdown item */
.dropdown-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.2s ease;
}

/* CSS for dropdown item hover effect */
.dropdown-item:hover {
  background-color: #f0f0f0;
}



.custom-datepicker-input {
  box-sizing: border-box; /* Ensures padding and borders are included in the width */
  display: flex;
}

.react-datepicker-wrapper {

  flex: 1;

  font-size: 14px;}

.react-datepicker__input-container input {
  width: 94%; /* Adjusts the input field's width */
  display: flex;
}

@media (max-width: 600px) {
  .modal-content{
    width:100%;
    max-width: 100%;
    max-height: 90%;
    padding-bottom: 50px;
    padding-right: 10px;
    overflow-x:hidden; 


  }
  .company-info-modal {
max-width: 99%;
  }


  .button-group {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Make buttons full width */
  }

  .modal-save-button,
  .modal-delete-button,
  .modal-toggle-status-button {
    width: 100%; /* Full width buttons on mobile */
  }

.form-group {
display: contents;
}
}

.danger-button {
  background-color: var(--danger-color);
}

.danger-button:hover{
  background-color: var(--danger-color-hover);
}