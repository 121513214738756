/* Import variables */
@import './variables.css';

/* Reset and base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--background-main);
  line-height: 1.5;
  padding-top: 60px; /* Height of navbar */
}

/* Page layout containers */
.page-wrapper {
  padding: var(--spacing-xl) var(--spacing-lg);
  max-width: 1440px;
  margin: 0 auto;
}

.content-wrapper {
  padding: var(--spacing-xl) var(--spacing-lg);
}

/* Page titles */
.page-title-container {
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  padding: var(--spacing-lg) var(--spacing-xl);
}

.page-title {
  font-size: var(--font-size-xl);
  font-weight: bold;
  margin-bottom: var(--spacing-lg);
  max-width: 1440px;
  margin: 0 auto;
}

.section-title {
  font-size: var(--font-size-lg);
  font-weight: bold;
  margin-bottom: var(--spacing-md);
}

.subsection-title {
  font-size: var(--font-size-md);
  font-weight: bold;
  margin-bottom: var(--spacing-sm);
}

/* Section wrapper */
.section-wrapper {
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

/* Common component styles */
.card {
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-lg);
  margin: var(--spacing-md);
  transition: box-shadow var(--transition-normal);
}

.card:hover {
  box-shadow: var(--shadow-lg);
}

/* Button styles */
.btn {
  display: inline-block;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all var(--transition-normal);
  border: 1px solid transparent;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-primary:hover {
  background-color: var(--primary-hover);
}

.btn-secondary {
  background-color: var(--white);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn-secondary:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

/* Form styles */
.form-group {
  margin-bottom: var(--spacing-md);
}

.form-control {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-md);
  transition: border-color var(--transition-normal);
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color);
}

/* Grid and layout utilities */
.grid {
  display: grid;
  gap: var(--spacing-lg);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

/* Spacing utilities */
.m-0 { margin: 0; }
.mt-sm { margin-top: var(--spacing-sm); }
.mb-sm { margin-bottom: var(--spacing-sm); }
.mt-md { margin-top: var(--spacing-md); }
.mb-md { margin-bottom: var(--spacing-md); }
.mt-lg { margin-top: var(--spacing-lg); }
.mb-lg { margin-bottom: var(--spacing-lg); }

.p-0 { padding: 0; }
.pt-sm { padding-top: var(--spacing-sm); }
.pb-sm { padding-bottom: var(--spacing-sm); }
.pt-md { padding-top: var(--spacing-md); }
.pb-md { padding-bottom: var(--spacing-md); }
.pt-lg { padding-top: var(--spacing-lg); }
.pb-lg { padding-bottom: var(--spacing-lg); }

/* Typography utilities */
.text-sm { font-size: var(--font-size-sm); }
.text-md { font-size: var(--font-size-md); }
.text-lg { font-size: var(--font-size-lg); }
.text-xl { font-size: var(--font-size-xl); }

.font-bold { font-weight: bold; }
.text-primary { color: var(--primary-color); }
.text-light { color: var(--text-light); }
.text-lg { font-size: var(--font-size-lg); }
.text-xl { font-size: var(--font-size-xl); }

.font-bold { font-weight: bold; }
.text-primary { color: var(--primary-color); }
.text-light { color: var(--text-light); }
