.order-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 8px;
  max-height: 120px; /* Allow for up to 3 rows before scrolling */
  overflow-y: auto;
}

.order-pill {
  padding: 8px 16px;
  border: 1px solid #dee2e6;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  transition: all 0.2s ease;
  min-width: 45px;
  text-align: left;
  display: flex;
  align-items: center;
  flex: 0 1 auto; /* Allow pills to shrink but not grow */
  max-width: calc(50% - 8px); /* Maximum width of half the container minus gap */
}

.order-pill .current-order,
.order-pill .derivative-order,
.order-pill .base-order {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
}

.order-pill .pill-order-id {
  font-weight: normal;
  min-width: 50px;
  flex-shrink: 0;
}

.order-pill .pill-company {
  font-size: 12px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  border-left: 1px solid #dee2e6;
  flex: 1;
  min-width: 0; /* Allow text to truncate */
}

.order-pill:hover {
  background: #e9ecef;
}

.order-pill.active {
  background: #007bff;
  color: white;
  border-color: #0056b3;
}

.order-pill .current-order {
  font-weight: bold;
}

.order-pill .derivative-order {
  color: #666;
  font-size: 14px;
}

.order-pill .base-order {
  color: #333;
}

.order-pill.active .derivative-order,
.order-pill.active .base-order,
.order-pill.active .pill-company {
  color: white;
}

.order-pill.active .pill-company {
  border-left-color: rgba(255, 255, 255, 0.3);
}

.order-pill {
  position: relative;
}

.order-pill:hover::after {
  content: attr(title);
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .order-pill {
    max-width: 100%; /* Full width on mobile */
  }
  
  .order-pills {
    max-height: 160px; /* Allow for more rows on mobile */
  }
}

.dropdown {
    position: relative;
    flex: 1;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 0;
    margin: 0;
    list-style: none;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    overflow: auto; /* Ensure modal itself can scroll */
}

.modal-content {
    background-color: #fefefe; /* White background */
    padding: 20px;
    max-width: 700px;
    max-height: 80%; /* Limit height to allow scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    
}

.table-add-items{
    max-width: 700px;
}

.close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

.form-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.form-group {
    display: flex;
    margin-bottom: 2px;
    flex: 1 1 45%;
    margin: 5px;
}

.label {
    flex: 1;
    text-align: left;
    margin-bottom: 3px;
    margin-right: 10px;
    font-weight: bold;
}

.input {
    flex: 1;
    padding: 8px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

textarea.input {
    resize: vertical;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
}

.btn-save,
.btn-discard {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-save {
    background-color: #4caf50;
    color: white;
}

.btn-save:hover {
    background-color: #45a049;
}

.btn-discard {
    background-color: #f44336;
    color: white;
}

.btn-discard:hover {
    background-color: #e53935;
}

.company-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    z-index: 1100;
}

.company-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 1050;
}

.company-search {
    margin-bottom: 16px;
    position: relative;
}

.company-search input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
}

.company-search input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.company-list {
    overflow-y: auto;
    flex: 1;
    margin: 0 -20px;
    padding: 0 20px;
    min-height: 200px;
    max-height: calc(80vh - 140px);
}

.company-item {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    transition: background-color 0.2s;
    margin: 0 -20px;
}

.company-item:hover {
    background-color: #f8f9fa;
}

.company-item:last-child {
    border-bottom: none;
}

.company-name {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
    color: #333;
}

.company-country {
    font-size: 12px;
    color: #666;
    display: flex;
    align-items: center;
}

.company-country::before {
    content: "📍";
    margin-right: 4px;
    font-size: 12px;
}

.buyer-display {
    display: flex;
    align-items: center;
    flex: 1;
}

.buyer-text {
    padding: 8px 12px;
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1;
    font-size: 14px;
    color: #333;
    min-height: 36px;
    display: flex;
    align-items: center;
}

.modal-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #333;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.2s;
    display: flex;
    align-items: top;
    justify-content: center;
    margin: 0;
}

.edit-button:hover {
    background-color: #f0f0f0;
}

.company-modal-footer {
  padding: 15px;
  border-top: 1px solid #eee;
  text-align: center;
}

.add-company-button {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  transition: all 0.2s ease;
}

.add-company-button:hover {
  background-color: #e9ecef;
  border-color: #ccc;
}
