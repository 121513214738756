/* AdminUsers.module.css */
.container {
  padding-top: 40px; /* Space to avoid the navigation bar */
  background-color: #f8f9fa; /* Light gray background for the container */
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px; /* Space between rows */
}

.table th, .table td {
  padding: 10px;
  border: 1px solid #dee2e6;
  background-color: #ffffff; /* White background for cells */
}

.actions {
  display: flex;
  gap: 10px; /* Space between dropdown and buttons */
}

.select, .button {
  padding: 5px;
}

.button {
  background-color: #17a2b8; /* Accent color for buttons */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #138496; /* Darker shade on hover */
}
