/* ordersTable.css (or any appropriate file) */

.orders-table-container {
  width: 100%;
  overflow-x: auto;
}

.order-link{
color: #138496;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.orders-table th, .orders-table td {
  border: 1px solid #ddd;
  padding: 8px; /* Reduced padding for cells */
  text-align: left;
  font-size: 14px; /* Adjusted font size */
  vertical-align: middle; /* Center content vertically */
  word-wrap: break-word; /* Enable word wrapping */
  white-space: pre-wrap; /* Preserve white space and wrap text */
}

.orders-table th {
  background-color: #e7f2fb; /* Light blue background for headers */
}

.orders-table.orders-table-1 tbody tr:nth-child(even) {
  background-color: #edf6ff; /* Light gray background for even rows */
}
.orders-table.orders-table-2 tbody tr:nth-child(even) {
  background-color: #edf6ff; /* Light gray background for even rows */
}

.orders-table.orders-table-1 tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}
.orders-table.orders-table-2 tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

.orders-table tbody tr:hover {
  background-color: #e3f1ff; /* Light blue background on hover */
  color:#000;
}

.orders-table td button {
  padding: 6px 12px; /* Adjusted button padding */
  background-color: #17a2b8; /* Light blue button color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.orders-table td button:hover {
  background-color: #138496; /* Darker blue on hover */
}

/* Base order styling */
.base-order-row {
  background-color: #ffffff;
}

/* Derivative order styling */
.derivative-order-row {
  background-color: #f8f9fa;
}

.derivative-order-row td {
  color: #666;
}

.derivative-order-row td:first-child {
  padding-left: 25px;
}

.derivative-arrow {
  display: inline-block;
  margin-right: 8px;
  color: #666;
  font-size: 18px;
}

/* Arrow indicator for derivative orders */

/* Action buttons */
.action-button {
  margin: 0 4px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.action-button:hover {
  background-color: #0056b3;
}

/* Company info button */
.company-info-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

.company-info-button:hover {
  color: #0056b3;
}

/* Actions cell */
.actions-cell {
  white-space: nowrap;
}

/* Invoice Preview Popup */
.invoice-preview {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.preview-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 90%; /* Adjusted width */
  height: 90%; /* Adjusted height */
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure relative positioning for child elements */
}

.preview-frame {
  width: 100%;
  border: none;
  height: 100%;
  max-height: 100%; /* Ensure it fills the available height */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #dc3545; /* Red background color */
  color: white;
  border: none;
  border-radius: 50%; /* Rounded shape */
  width: 36px; /* Adjusted width */
  height: 36px; /* Adjusted height */
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.close-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

/* Preview modal */
.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-modal iframe {
  width: 90%;
  height: 90%;
  border: none;
  background: white;
}

.preview-modal .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
}

/* Set column widths by percentage */
.orders-table.orders-table-1 th:nth-child(1),
.orders-table.orders-table-1 td:nth-child(1) { width: 7%; }
.orders-table.orders-table-1 th:nth-child(2),
.orders-table.orders-table-1 td:nth-child(2) { width: 8%; }
.orders-table.orders-table-1 th:nth-child(3),
.orders-table.orders-table-1 td:nth-child(3) { width: 7%; }
.orders-table.orders-table-1 th:nth-child(4),
.orders-table.orders-table-1 td:nth-child(4) { width: 7%; }
.orders-table.orders-table-1 th:nth-child(5),
.orders-table.orders-table-1 td:nth-child(5) { width: 7%; }
.orders-table.orders-table-1 th:nth-child(6),
.orders-table.orders-table-1 td:nth-child(6) { width: 12%; }
.orders-table.orders-table-1 th:nth-child(7),
.orders-table.orders-table-1 td:nth-child(7) { width: 27%; }
.orders-table.orders-table-1 th:nth-child(8),
.orders-table.orders-table-1 td:nth-child(8) { width: 5%; }
.orders-table.orders-table-1 th:nth-child(9),
.orders-table.orders-table-1 td:nth-child(9) { width: 5%; }
.orders-table.orders-table-1 th:nth-child(10),
.orders-table.orders-table-1 td:nth-child(10) { width: 5%; }
.orders-table.orders-table-1 th:nth-child(11),
.orders-table.orders-table-1 td:nth-child(11) { width: 5%; }
.orders-table.orders-table-1 th:nth-child(12),
.orders-table.orders-table-1 td:nth-child(12) { width: 5%; }

  /* Set column widths by percentage */
  .orders-table.orders-table-2 th:nth-child(1),
  .orders-table.orders-table-2 td:nth-child(1) { width: 7%; }
  .orders-table.orders-table-2 th:nth-child(2),
  .orders-table.orders-table-2 td:nth-child(2) { width: 8%; }
  .orders-table.orders-table-2 th:nth-child(3),
  .orders-table.orders-table-2 td:nth-child(3) { width: 7%; }
  .orders-table.orders-table-2 th:nth-child(4),
  .orders-table.orders-table-2 td:nth-child(4) { width: 7%; }
  .orders-table.orders-table-2 th:nth-child(5),
  .orders-table.orders-table-2 td:nth-child(5) { width: 7%; }
  .orders-table.orders-table-2 th:nth-child(6),
  .orders-table.orders-table-2 td:nth-child(6) { width: 12%; }
  .orders-table.orders-table-2 th:nth-child(7),
  .orders-table.orders-table-2 td:nth-child(7) { width: 10%; }
  .orders-table.orders-table-2 th:nth-child(8),
  .orders-table.orders-table-2 td:nth-child(8) { width: 17%; }
  .orders-table.orders-table-2 th:nth-child(9),
  .orders-table.orders-table-2 td:nth-child(9) { width: 5%; }
  .orders-table.orders-table-2 th:nth-child(10),
  .orders-table.orders-table-2 td:nth-child(10) { width: 5%; }
  .orders-table.orders-table-2 th:nth-child(11),
  .orders-table.orders-table-2 td:nth-child(11) { width: 5%; }
  .orders-table.orders-table-2 th:nth-child(12),
  .orders-table.orders-table-2 td:nth-child(12) { width: 5%; }
  .orders-table.orders-table-2 th:nth-child(13),
  .orders-table.orders-table-2 td:nth-child(13) { width: 5%; }

.export-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.export-button:hover {
  background-color: #218838;
}

.total-sum-number-invoice {
  text-align: right; /* Right-aligns the text */
  font-family: 'Courier New', Courier, monospace; /* Applies a monospace font */
  white-space: nowrap; /* Prevents text from wrapping */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}