.profileSettingsPage {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  background-image: url('../../public/SomitekWatermark.png'); /* Update this path */
  background-repeat: repeat; /* Ensures the pattern repeats */
  background-size: 150px 150px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  height:85vh;
  margin: 0;
}

.profileSettingsContainer {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.profileSettingsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.inputGroup label {
  margin-top: 10px;
}

button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #17a2b8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #138496;
}

button + button {
  margin-top: 10px;
}

i.fas.fa-user.fa-5x {
  margin-bottom: 10px;
}