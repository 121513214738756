/* ForgotPasswordPage.module.css */

.forgotPasswordContainer {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  background-image: url('../../public/SomitekWatermark.png'); /* Update this path */
  background-repeat: repeat; /* Ensures the pattern repeats */
  background-size: 150px 150px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  margin: 0;
}
  
  .forgotPasswordBox {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }
  
  .ForgotPasswordInput {
    display: block;
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #17a2b8;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #138496;
  }
  
  