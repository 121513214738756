.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .title {
    color: #17a2b8;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .invoiceList {
    width: 100%;
  }
  
  .invoiceVendor {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
  }
  
  .vendorTitle {
    color: #17a2b8;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .invoiceItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .label {
    flex: 1;
    font-weight: bold;
  }
  
  .input {
    flex: 2;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .buttons {
    margin-top: 20px;
  }
  
  .button {
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #138496;
  }
  
  .message {
    text-align: center;
    font-size: 18px;
    color: #333;
  }
  