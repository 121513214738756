/* src/css/CompanyDetail.css */

.company-detail-row {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #ddd; /* Add a border for a table-like appearance */
    text-align: left;

  }
  
  .company-detail-title {
    flex: 1;
    font-weight: bold;
    color: #333;
    /* Ensure the title text wraps */
    white-space: normal;
    word-break: break-word; /* Break long words to fit within the container */
  }
  
  .company-detail-content {
    flex: 2;
    color: #333;
    /* Ensure the content text wraps */
    white-space: normal;
    word-break: break-word; /* Break long words to fit within the container */
  }
  
  /* Additional styles to make links look appropriate */
  .company-detail-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .company-detail-content a:hover {
    text-decoration: underline;
  }
  