/* loginPage.module.css */

.signInContainer {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  background-image: url('../../public/SomitekWatermark.png'); /* Update this path */
  background-repeat: repeat; /* Ensures the pattern repeats */
  background-size: 150px 150px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  margin: 0;
}

.signInCard {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.signInTitle {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputGroup label {
  margin-top: 10px;
}

.signInInput {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.signInInput:focus {
  border-color: #17a2b8;
  outline: none;
}

.signInButton {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #17a2b8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.signInButton:hover {
  background-color: #138496;
}

.forgotPasswordButton {
  background: none;
  border:#138496;
  border-width: 1px;
  color: #17a2b8;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.forgotPasswordButton:hover {
  color: #138496;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-top: 10px;
}
