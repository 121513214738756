.macos-window {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5; /* Light gray background to mimic macOS window */
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .macos-header {
    padding: 10px;
    background-color: #e0e0e0; /* Darker header to match macOS style */
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .window-title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .macos-content {
    padding: 10px;
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .event-item {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.3s ease;
    z-index: 10001;
  }
  
  .event-item h3, .event-item p {
    margin: 0;
    font-size: 1rem; /* Responsive text size */
  }
  
  .event-item.past-due {
    background-color: #ffe6e6; /* Light red background for past-due events */
  }
  
  .event-item.upcoming {
    background-color: #e6ffe6; /* Light green background for upcoming events */
  }
  
  .all-events-link {
    padding: 5px 10px;
    background-color: #17a2b8;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .all-events-link:hover {
    background-color: #005bb5;
  }

  .overdue-notice {
    color: #f00; /* Red color for the overdue notice */
    font-weight: bold;
    margin-bottom: 10px; /* Space below the notice */
  }

  /* EventWidget.css */

/* EventWidget.css */

/* EventWidget.css */

.macos-content .todays-events-notice {
    font-weight: bold;
    color: #ffeb3b; /* Color for the "for today" text */
    margin-bottom: 10px;
  }
  
  .macos-content .tomorrows-events-notice {
    font-weight: bold;
    color: #aeeeee; /* Color for the "for tomorrow" text */
    margin-bottom: 10px;
  }
  
  .event-item, .event-card {
    padding: 10px;
    margin-bottom: 10px;
    z-index: 1; /* Ensure correct stacking order */
  }
  
  .event-item.past-due {
    border: 1px solid #f5c6cb;
    background-color: #f8d7da;
    z-index: 2; /* Higher stacking order for past due events */
  }
  
  .event-card[data-background-color='light-yellow'] {
    background-color: #ffffe0; /* Light yellow background */
  }

  /* EventWidget.css */

.macos-content .event-notice {
    background-color: #ffffff; /* Light gray background for the rounded card */
    color: #000; /* Black text color */
    border-radius: 12px; /* Rounded corners */
    padding: 8px 16px; /* Padding inside the card */
    margin-bottom: 10px; /* Space below the card */
    text-align: center; /* Center text */
    font-weight: bold;
  }
  
  .macos-content .overdue-notice {
    font-weight: bold;
    color: #ff0015; /* Red color for overdue text */
    margin-bottom: 10px;
    background-color: #f8d7da; /* Light gray background for the rounded card */
    border-radius: 12px; /* Rounded corners */
    padding: 8px 16px; /* Padding inside the card */
    margin-bottom: 10px; /* Space below the card */
    text-align: center; /* Center text */
    font-weight: bold;
  }
  
  .event-item, .event-card {
    padding: 10px;
    margin-bottom: 10px;
    z-index: 1; /* Ensure correct stacking order */
  }
  
  .event-item.past-due {
    border: 1px solid #f5c6cb;
    background-color: #f8d7da;
    z-index: 2; /* Higher stacking order for past due events */
  }
  
  
  