/* Archive.css */

.archive-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .archive-controls {
    margin-bottom: 20px;
  }
  
  .search-controls,
  .filter-controls,
  .date-range-controls {
    margin-bottom: 20px;
  }
  
  .search-controls input,
  .filter-controls select,
  .date-range-controls input {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
  }
  
  .search-controls input {
    width: 200px;
  }
  
  .filter-controls select,
  .date-range-controls input {
    width: 200px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .orders-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .orders-table th,
  .orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .orders-table th {
    background-color: #f4f4f4;
  }
  
  .date-range-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1rem 0;
  }

  .date-input-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .date-input-group label {
    font-weight: 500;
    color: #333;
  }

  .date-input-group input[type="date"] {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  .date-input-group input[type="date"]:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }

  /* Delete confirmation modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
   /* max-width: 500px;*/
    width: 90%;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }

  .modal-buttons button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-buttons button:first-child {
    background-color: #dc3545;
    color: white;
  }

  .modal-buttons button:last-child {
    background-color: #6c757d;
    color: white;
  }