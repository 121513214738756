/* Import base styles */
@import './base.css';

/* Navigation bar styles */
.navbar {
  background-color: var(--background-light);
  padding: var(--spacing-md) 0; /* Remove horizontal padding */
  box-shadow: var(--shadow-sm);
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: var(--z-index-navbar);
  transition: height var(--transition-normal);
}

.navbar .nav-content {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-xl); /* Add padding to inner content */
}

.logout-btn{
  margin-right: var(--spacing-sm);
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar .pages {
  display: flex;
  align-items: center;
  margin: 0;
}

.navbar .actions {
  display: flex;
  align-items: center;
  margin: 0;
}

.navbar li {
  margin-right: var(--spacing-md);
}

.navbar a {
  text-decoration: none;
  color: var(--text-color);
  padding: var(--spacing-sm);
  transition: color var(--transition-normal);
}

.navbar a:hover {
  color: var(--secondary-color);
}

.navbar .active {
  font-weight: bold;
  color: var(--secondary-color);
}

.navbar .logout-btn {
  margin-left: var(--spacing-sm);
  padding-right: var(--spacing-sm);
}

.navbar .actions li button {
  background-color: var(--background-light);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-sm);
}

.navbar .actions li button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: var(--font-size-xl);
  color: var(--primary-color);
  cursor: pointer;
}

.navbar.open .pages,
.navbar.open .actions {
  display: block;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: var(--background-light);
  box-shadow: var(--shadow-sm);
  padding: var(--spacing-sm) 0;
  z-index: var(--z-index-navbar);
}

.navbar.open .pages {
  margin-top: 0; /* Ensure there is no margin above the menu items */
}

.navbar.open .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align logout options to the right */
  padding: 0 var(--spacing-lg); /* Add padding to align items */
  margin-top: var(--spacing-sm); /* Margin to separate from menu */
}

.navbar .pages.show,
.navbar .actions.show {
  display: flex;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: var(--spacing-sm) 0;
  }

  .navbar .nav-content {
    padding: 0 var(--spacing-md);
  }

  .navbar .pages {
    display: none;
    flex-direction: column;
    margin-top: var(--spacing-sm);
    padding-top: var(--spacing-lg);
  }

  .navbar.open .pages {
    display: flex;
  }

  .navbar li {
    padding-top: var(--spacing-md);
    font-size: var(--font-size-lg);
    opacity: 0; /* Start hidden */
    animation: fadeIn 0.5s forwards; /* Apply fadeIn animation */
  }

  .navbar .pages li:nth-child(1) {
    animation-delay: 0.1s; /* Delay for first item */
  }

  .navbar .pages li:nth-child(2) {
    animation-delay: 0.2s; /* Delay for second item */
  }

  .navbar .pages li:nth-child(3) {
    animation-delay: 0.3s; /* Delay for third item */
  }

  .navbar .pages li:nth-child(4) {
    animation-delay: 0.4s; /* Delay for fourth item */
  }

  .navbar .pages li:nth-child(5) {
    animation-delay: 0.5s; /* Delay for fifth item */
  }

  .navbar .pages li:nth-child(6) {
    animation-delay: 0.6s; /* Delay for sixth item */
  }

  .navbar .pages li:nth-child(7) {
    animation-delay: 0.7s; /* Delay for seventh item */
  }

  .navbar .pages li:nth-child(8) {
    animation-delay: 0.8s; /* Delay for eighth item */
  }

  .navbar .pages li:nth-child(9) {
    animation-delay: 0.9s; /* Delay for ninth item */
  }

  .navbar .pages li:nth-child(10) {
    animation-delay: 1s; /* Delay for tenth item */
  }

  .navbar .user-name {
    font-size: var(--font-size-sm);
  }

  .navbar.open .actions.show {
    display: none;
  }
  
  .navbar.open .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: var(--spacing-sm); /* Margin to separate from menu */
  }

  .hamburger {
    display: block;
  }

  .navbar.open .pages,
  .navbar.open .actions {
    padding: var(--spacing-md) var(--spacing-md);
  }
}
