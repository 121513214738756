.event-card {
    background-color: #fff; /* White background for cards */
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .event-card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .event-card p {
    margin: 5px 0;
    color: #666;
  }
  
  .event-card-button {
    padding: 8px 16px;
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .event-card-button:hover {
    background-color: #139ab8;
  }
  