.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    overflow: auto; /* Ensure modal itself can scroll */
  }
  
  .modal-content-multiple {
    background-color: #fefefe; /* White background */
    padding: 20px;
    max-width: 1400px;
    max-height: 80%; /* Limit height to allow scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
  }