/* CompanyInfoModal.css */
.company-info-modal {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-lg);
}

.company-info-modal form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.company-info-modal label {
  font-weight: 500;
  margin-bottom: var(--spacing-xs);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-button:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .company-info-modal {
    width: 95%;
    margin: var(--spacing-md) auto;
    padding: var(--spacing-md);
  }
}