.container {
    width: 100%; /* Make container use full width of its parent */
    max-width: 100%; /* Ensure it doesn't exceed cell width */
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Include padding and border in the element’s total width and height */
  }
  
  .title {
    color: #17a2b8;
    font-size: 24px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .input, .select {
    width: calc(100% - 22px); /* Adjust width to fit the container, considering padding */
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .button {
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Make button full-width */
  }
  
  .button:hover {
    background-color: #138496;
  }
  