/* Style for the Debtors Page */
.debtors-page {
  padding: 20px;
}

/* Color legend styles */
.color-legend {
  margin: 20px 0;
}

.color-legend h2 {
  margin-bottom: 10px;
}

.color-legend ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.color-legend li {
  margin-right: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.color-legend .color-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #ddd;
}

/* Grid layout for tables */
.table-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns on large screens */
  grid-template-rows: auto auto; /* Two rows on large screens */
  gap: 20px; /* Space between grid items */
}

/* Ensure correct placement in grid */
.table-item:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}

.table-item:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
}

.table-item:nth-child(3) {
  grid-column: 1 / span 1; /* Span both columns */
  grid-row: 2;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .table-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    grid-template-rows: repeat(3, auto); /* Three rows for three items */
  }

  /* Ensure correct placement in grid */
  .table-item:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }

  .table-item:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  .table-item:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
  }
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th, .table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.table-container th {
  background-color: #e7f2fb;
}

.table-container tbody tr:hover {
  background-color: #e3f1ff;
}
