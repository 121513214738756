.offline-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
}

.offline-alert-box {
  background: white;
  border-radius: 12px;
  padding: 30px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  animation: fadeInScale 0.3s ease-out;
}

.offline-alert-icon {
  width: 100px;
  height: 100px;
  background: #f44336;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.offline-alert-icon i {
  font-size: 60px;
  color: white;
  animation: bounceIn 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.error-code {
  color: #f44336;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  font-family: monospace;
  letter-spacing: 1px;
}

.offline-alert h2 {
  color: #333;
  margin: 0 0 15px;
  font-size: 24px;
}

.offline-alert p {
  color: #666;
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1.5;
}

.offline-alert-details {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.offline-alert-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.offline-alert-details li {
  color: #666;
  padding: 8px 0;
  padding-left: 24px;
  position: relative;
}

.offline-alert-details li:before {
  content: "•";
  color: #f44336;
  position: absolute;
  left: 8px;
}

.offline-alert-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #666;
  font-size: 14px;
}

.offline-alert-dot {
  width: 8px;
  height: 8px;
  background: #f44336;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
