/* Analytics Section Styling */
.dashboard-section {
  padding: 0;
  background: #f5f7fa;
  min-height: 100vh;
}

.analytics-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  overflow: visible;
}

.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon {
  font-size: 20px;
  color: #1890ff;
}

.analytics-content {
  display: none;
  background: #fff;
}

.analytics-content.expanded {
  display: block;
}

.analytics-body {
  display: flex;
  gap: 24px;
  padding: 24px;
  background: #fff;
}

.analytics-sidebar {
  width: 300px;
  flex-shrink: 0;
}

.analytics-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 24px;
}

.chart-section {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-height: 400px;
}

.chart-section.large {
  grid-column: 1 / -1;
  min-height: 500px;
}

.kpi-section {
  width: 100%;
  margin-bottom: 24px;
}

.kpi-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

/* Chevron animation */
.chevron {
  transition: transform 0.3s ease;
}

.chevron.expanded {
  transform: rotate(180deg);
}

.kpi-section {
  margin-bottom: 30px;
}

.kpi-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.kpi-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  animation: fadeIn 0.3s ease;
}

.kpi-card:hover {
  transform: translateY(-2px);
}

.kpi-title {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}

.kpi-value {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.kpi-currency,
.kpi-unit {
  font-size: 16px;
  color: #666;
  margin-left: 4px;
}

.kpi-comparison {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 6px;
  width: fit-content;
}

.kpi-comparison.positive {
  color: #4caf50;
  background: rgba(76, 175, 80, 0.1);
}

.kpi-comparison.negative {
  color: #f44336;
  background: rgba(244, 67, 54, 0.1);
}

/* Charts Grid */
.charts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.chart-section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chart-section.large {
  grid-column: span 2;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #262626;
}

.metric-toggle {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
}

/* Switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1890ff;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #1890ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.metric-toggle span {
  font-size: 14px;
  color: #595959;
  user-select: none;
}

/* Map specific styles */
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.map-controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.map-control-btn {
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.map-control-btn:hover {
  background: #f5f5f5;
  border-color: #bbb;
}

.map-control-btn svg {
  width: 16px;
  height: 16px;
  color: #666;
}

.map-container svg {
  max-width: 100%;
  height: auto;
}

/* Country Heatmap specific styles */
.rsm-geography {
  cursor: pointer;
  transition: all 0.3s ease;
}

.rsm-geography:hover {
  opacity: 0.9;
}

.country-heatmap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.metric-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  flex-shrink: 0;
}

/* Switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1890ff;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff4d4f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.metric-toggle span {
  font-size: 14px;
  color: #595959;
  user-select: none;
}

.radio-switch {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.radio-switch:hover {
  background-color: #f0f0f0;
}

.radio-switch input[type="radio"] {
  position: relative;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  margin-right: 8px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}

.radio-switch input[type="radio"]:checked {
  border-color: #1890ff;
  background-color: #1890ff;
}

.radio-switch input[type="radio"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
}

.radio-label {
  font-size: 14px;
  color: #595959;
}

.tooltip {
  position: absolute;
  pointer-events: none;
  background: white;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  font-size: 14px;
  z-index: 1000;
  transform: translate(-50%, -100%);
  top: 0;
  left: 50%;
}

/* Tooltip styles */
#country-tooltip {
  background-color: white !important;
  color: #333 !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
}

/* Filter Styles */
.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filters-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.clear-filters {
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.clear-filters:hover {
  text-decoration: underline;
}

.timeframe-filter {
  margin-bottom: 24px;
}

.timeframe-filter label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

.timeframe-filter select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  color: #1a1a1a;
  background: white;
}

.custom-date-range {
  display: flex;
  gap: 10px;
}

.custom-date-range input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.custom-date-range input:hover {
  border-color: #40a9ff;
}

.custom-date-range input:focus {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
}

.filter-group {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-group label {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.filter-group select {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-group select:hover {
  border-color: #40a9ff;
}

.filter-group select:focus {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.date-range-display {
  flex: 2;
}

.date-range-text {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #666;
}

/* Ant Design RangePicker customization */
.ant-picker-range {
  width: 100%;
}

/* Tree Filter Styles */
.tree-filter {
  margin-bottom: 20px;
}

.tree-header {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  color: #1a1a1a;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.tree-header:hover {
  background: #f5f7fa;
}

.tree-arrow {
  margin-right: 8px;
  font-size: 12px;
  transition: transform 0.2s ease;
}

.tree-header.expanded .tree-arrow {
  transform: rotate(90deg);
}

.tree-content {
  margin: 8px 0 8px 24px;
}

.tree-search {
  margin-bottom: 12px;
}

.tree-search input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.tree-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tree-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #1a1a1a;
}

.tree-item:hover {
  background: #f5f7fa;
}

.tree-item input[type="checkbox"] {
  margin: 0;
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
  .analytics-container {
    flex-direction: column;
  }

  .analytics-filters {
    width: auto;
    position: static;
    margin-bottom: 20px;
  }

  .charts-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .dashboard-section {
    padding: 12px;
  }

  .kpi-cards {
    grid-template-columns: 1fr;
  }

  .kpi-value {
    font-size: 24px;
  }

  .chart-section {
    padding: 16px;
  }

  .custom-date-range {
    flex-direction: column;
  }
}

/* No Data State */
.no-data {
  text-align: center;
  padding: 40px;
  color: #666;
  background: white;
  border-radius: 12px;
  margin-top: 20px;
}

/* Loading States */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #666;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  width: 100%;
  gap: 20px;
}

.loading-container p {
  color: #666;
  font-size: 16px;
  margin: 0;
}

/* Customize Ant Design Spin component */
.ant-spin-dot {
  font-size: 24px;
}

.ant-spin-text {
  margin-top: 8px;
  font-size: 14px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Simplified Date Filter Layout */
.date-filter-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.date-type-select,
.date-period-select {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.date-type-select:hover,
.date-period-select:hover {
  border-color: #40a9ff;
}

.date-type-select:focus,
.date-period-select:focus {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.custom-date-range {
  display: flex;
  gap: 8px;
}

.custom-date-range input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.custom-date-range input:hover {
  border-color: #40a9ff;
}

.custom-date-range input:focus {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.date-range-text {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #666;
}

.filter-checkbox-row {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.filter-checkbox-row:last-child {
  border-bottom: none;
}

.filter-checkbox-row label {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 8px;
}

.filter-checkbox-row input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.checkbox-label {
  flex: 1;
  font-size: 14px;
  color: #333;
}

.type-badge {
  padding: 2px 8px;
  border-radius: 12px;
  background-color: #f5f5f5;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #f0f0f0;
}

.filter-header:hover {
  background-color: #fafafa;
}

.filter-header h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.arrow {
  font-size: 12px;
  transition: transform 0.2s ease;
  color: #666;
}

.arrow.expanded {
  transform: rotate(180deg);
}

.filter-options {
  padding: 8px 16px;
  max-height: 300px;
  overflow-y: auto;
}

.filter-options::-webkit-scrollbar {
  width: 6px;
}

.filter-options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.filter-options::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.filter-options::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.filter-header-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selected-count {
  font-size: 12px;
  color: #666;
  font-weight: normal;
}

.filter-section {
  margin-bottom: 8px;
}

.filter-card {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.heatmap-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
}

.heatmap-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Ant Design Radio Button customization */
.ant-radio-group {
  display: flex;
  gap: 8px;
}

.ant-radio-button-wrapper {
  height: 32px;
  line-height: 30px;
  padding: 0 16px;
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #fff;
  color: #333;
  transition: all 0.3s;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

.ant-radio-button-wrapper:hover {
  color: #1890ff;
  border-color: #1890ff !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #1890ff !important;
  border-color: #1890ff !important;
  color: #fff !important;
  box-shadow: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #40a9ff !important;
  border-color: #40a9ff !important;
  color: #fff !important;
}
