.invoice {
    width: 210mm; /* A4 Width */
    min-height: 297mm; /* A4 Height */
    margin: auto;
    padding: 8mm;
    border: 1px solid #000;
    position: relative;
    box-sizing: border-box;
    margin-top: 30px;
  }

  .editable-field {
    transition: background-color 0.3s;
  }
  
  .editable-field:hover {
    background-color: #f0f0f0; /* Highlight color */
  }
  .action-icon:hover{
        background-color: #138496;
    }
    .action-icon {
        margin-left: 10px;
        padding: 5px 10px;
        fill: none;
        background: none;
        color: #138496;
    }
button {
    margin-left: 10px; /* Space between text and button */
    padding: 5px 10px;
}
  
  
  .invoice-header,
  .invoice-footer {
    left: 0;
    right: 0;
  }
  
  .invoice-header {
    top: 0;
  }
  
  .invoice-footer {
    bottom: 0;
    text-align: center;
  }
  
  .invoice-body {
    margin-top: 10px; /* Adjust based on header height */
    margin-bottom: 10px; /* Adjust based on footer height */
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-table th,
  .invoice-table td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
  }
  
  .invoice-total {
    margin-top: 5px;
    font-size: 1.5em;
  }

  .delete-button {
    background: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
  }

  .button-container{
    position:relative;
    align-items: flex-start;
    flex-direction: column;
  }
.invoice-number{
    width: 100%;
    padding: 0;
}
  .invoice-number-number{
    width: 50%;
    text-align: left;
  }
  .invoice-number-date{
    width: 50%;
    text-align: right;
  }

  .invoice-company{
    justify-content: space-between;
  }
  
  .invoice-company th{
    font-size: 15px;
    font-weight: 500;
    margin:0;
    padding-left: 45px
  }

.buttons-action{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}
  .invoice-company p{
    margin:0;
  }

  .invoice{
    padding-top: 20px;
  }

  .invoice-table tfoot td{
text-align: right;
  }

  .box-for-company-details {
    border: 3px solid #000; /* Thickness, style, and color */
    padding: 3px; /* Optional: Adds padding inside the border */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    width: 85mm;
}
  .invoice-number-number p{
margin: 0;
font-weight: 400;
  }
  .invoice-number-number h2{
    padding: 0;
    font-weight: 700;
  }

  .invoice-cleint-information h3{
    padding: 0;
    margin: 0;
  }

  .invoice-cleint-information p{
    padding: 0;
    margin: 0;
  }

  .butttons-action {
    display: flex;
align-items: flex-start;
flex-direction: row;
  }

  
  .invoice-client-information p,
  .invoice-client-information h3{
    padding: 0;
    margin: 0;
  }

  .invoice-number-date p{
    padding: 0%;
    margin: 0;
  }

  @media print {
    @page { margin: 0;
    padding: 0; }

    body * {
      visibility: hidden; /* Hide everything */
    }
  
    #invoice, #invoice * {
      visibility: visible; /* Show only the invoice */
    }
  .invoice{
    padding-top: 0mm;
  }   
   #invoice {
      position: absolute; /* Position for printing */
      left: 0;
      top: 0;
      width: 199.5mm; /* A4 Width reduced by 5% */
      height: 282.15mm; /* A4 Height reduced by 5% */
      margin: 0; /* Remove margins */
      padding: 6mm; /* Keep padding */
      border: none; /* Remove borders */
    }
  
    .print-button,
    .edit-button,
    .delete-button,
    .confirm-button,
    .action-icon {
      display: none; /* Hide buttons when printing */
    }
  
    .invoice-footer {
      position: absolute; /* Fix footer to the bottom */
      bottom: 15mm; /* Adjust distance from bottom */
      width: 100%; /* Full width */
    }
  }
  
