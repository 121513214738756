/* Modal.css */
.modal-overlay-invoice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-invoice {

    flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 210mm; /* Adjust width as necessary */
}

textarea {
  width: 200mm;
  height: 20mm;
  resize: vertical; /* Allow vertical resizing */
}

  