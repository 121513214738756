.event-page-container {
  padding: 20px;
}

.filters-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping to next line if necessary */
  gap: 10px; /* Space between filter elements */
  margin-bottom: 20px;
}

.filter-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  width: 150px;
}

.filters-container .filter-dropdown{
margin-top: 0px;
margin-bottom: 0;
width: 250px;
z-index: 11000;
}
.filters-container .filter-input{
  width: 150px;
}

.react-datepicker-wrapper {
  max-width: 150px;
}

.add-button {
  padding: 8px 16px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0px;
}

.add-button:hover {
  background-color: #138496;
}

.filter-checkbox-label {
  display: flex;
  align-items: center;
}

.filter-checkbox {
  margin-right: 5px;
}

.cards-container {
  display: flex;
  flex-direction: column;
}

h2 {
  margin: 0;
  padding: 10px;
  font-size: 1.2em;
}

.event-table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.event-table td {
  padding: 10px;
  vertical-align: top;
  border: none; /* Remove table borders */
}

.column {
  vertical-align: top;
  width: 25%; /* Adjust width for columns */
  border-right: 3px solid #ccc; /* Add right border for separation */
}

.column:last-child {
  border-right: none; /* Remove right border from the last column */
}

.upcoming-events {
  width: 50%; /* Twice as wide as other columns */
}

.upcoming-events-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px;
}
/* Media query for mobile view */
@media (max-width: 768px) {
  .event-table {
    display: block; /* Change table to block for stacking */
  }

  .event-table td {
    display: block; /* Stack table cells vertically */
    width: 100%; /* Full width for each cell */
    border-bottom: 1px solid #ccc; /* Add a bottom border for separation */
    box-sizing: border-box; /* Ensure padding and borders are included in width */
  }

  .event-table tr {
    display: block; /* Block display for rows to stack them */
    margin-bottom: 10px; /* Space between rows */
  }

  .column {
    width: 100%; /* Full width for columns in mobile view */
    border-right: none; /* Remove right border in mobile view */
    border-bottom: none; /* Remove bottom border in mobile view */
    padding: 10px; /* Add padding for content spacing */
    box-sizing: border-box; /* Ensure padding is included in width */
  }

  .upcoming-events {
    width: 100%; /* Full width for upcoming events in mobile view */
  }

  .upcoming-events-grid {
    display: grid;
    grid-template-columns: 1fr; /* Single column grid for mobile view */
    gap: 10px;
  }

  .filters-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap; /* Allows wrapping to next line if necessary */
    gap: 10px; /* Space between filter elements */
    margin-bottom: 20px;
  }
}
