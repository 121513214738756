.overdue-widget {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.widget-header h2 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.all-events-link {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
}

.all-events-link:hover {
  text-decoration: underline;
}

.widget-content {
  flex: 1;
  overflow-y: auto;
  padding-top: 15px;
}

.orders-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-item {
  padding: 12px;
  border-radius: 6px;
  background-color: #f8f9fa;
  border-left: 4px solid transparent;
}

.order-item.purple { border-left-color: #d8a1c5; }
.order-item.pale-orange { border-left-color: #f5c6c7; }
.order-item.pale-yellow { border-left-color: #f9f3c6; }
.order-item.pale-green { border-left-color: #c6e5d9; }
.order-item.pale-blue { border-left-color: #c6e2f5; }
.order-item.gray { border-left-color: #e0e0e0; }

.order-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.company-name {
  font-weight: 500;
  color: #333;
}

.order-amount {
  font-weight: 500;
  color: #666;
}

.order-date {
  font-size: 13px;
  color: #888;
}

.no-orders {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}