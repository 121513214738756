.page-container {
  height: calc(100vh - 120px); /* Account for navbar + title area */
  margin-top: calc(60px + var(--spacing-xl)); /* navbar height + spacing */
}

.page-title {
  padding: var(--spacing-lg) var(--spacing-xl);
  margin-top: 60px; /* navbar height */
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
}:root {
  /* Colors */
  --primary-color: #138496;
  --primary-hover: #106e7d;
  --secondary-color: #17a2b8;
  --text-color: #333;
  --text-light: #666;
  --background-light: #f8f9fa;
  --background-main: #f5f5f5;
  --white: #fff;
  --border-color: #ddd;
  --danger-color: #dc3545;
  --danger-color-hover: #c82333;

  /* Spacing */
  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --spacing-xl: 30px;

  /* Typography */
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 24px;

  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 6px 12px rgba(0, 0, 0, 0.15);

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;

  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;

  /* Z-index layers */
  --z-index-modal: 1000;
  --z-index-navbar: 900;
  --z-index-dropdown: 800;
}
