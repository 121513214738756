/* modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.modal-buttons {
  display: flex;
  gap: var(--spacing-md);
}

.modal-buttons button {
  min-width: 100px;
}

.order-form-modal {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-lg);
}

.order-form-modal .modal-body {
  padding: var(--spacing-lg) 0;
}

@media (max-width: 768px) {
  .order-form-modal {
    width: 95%;
    margin: var(--spacing-md) auto;
    padding: var(--spacing-md);
  }
}