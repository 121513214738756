.step-progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-md);
  background-color: var(--background-light);
  border-radius: var(--border-radius-sm);
}

.step-indicator {
  flex: 1;
  text-align: center;
  padding: var(--spacing-sm);
  margin: 0 var(--spacing-xs);
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  color: var(--text-light);
  transition: all var(--transition-fast);
}

.step-indicator.active {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
  box-shadow: var(--shadow-sm);
}

.step-indicator.validated {
  background-color: var(--success-color);
  color: var(--white);
  border-color: var(--success-color);
}

.step-container {
  padding: var(--spacing-md);
  max-width: 100%;
}

.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.step-content {
  margin-bottom: var(--spacing-lg);
}

.step-sub-content{
  margin: 0, 20%;
  margin-left: 20%;
  margin-right: 20%;
}

.step-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--spacing-md);
}

.label {
  font-weight: 500;
  color: var(--text-dark);
  margin-bottom: var(--spacing-xs);
}

.input,
.select,
textarea {
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-base);
  width: 100%;
  transition: border-color var(--transition-fast);
}

.input:focus,
.select:focus,
textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.input:disabled {
  background-color: var(--background-light);
  cursor: not-allowed;
}

.jurisdiction-options {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.jurisdiction-options label {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
}

.jurisdiction-options input[type="radio"] {
  display: none;
}

.jurisdiction-options .pill {
  padding: var(--spacing-sm) var(--spacing-lg);
  border: 2px solid var(--border-color);
  border-radius: 50px;
  background-color: var(--white);
  transition: all 0.2s ease-in-out;
  min-width: 80px;
  text-align: center;
  font-weight: 500;
}

.jurisdiction-options .pill:hover {
  border-color: var(--primary-color);
  background-color: var(--background-light);
}

.jurisdiction-options input[type="radio"]:checked + .pill {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown {
  position: relative;
  width: 100%;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-md);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-item {
  padding: var(--spacing-sm);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.dropdown-item:hover {
  background-color: var(--background-light);
}

.order-type-options {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.order-type-options label {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
}

.order-type-options input[type="radio"] {
  display: none;
}

.order-type-options .pill {
  padding: var(--spacing-sm) var(--spacing-lg);
  border: 2px solid var(--border-color);
  border-radius: 50px;
  background-color: var(--white);
  transition: all 0.2s ease-in-out;
  min-width: 120px;
  text-align: center;
  font-weight: 500;
}

.order-type-options .pill:hover {
  border-color: var(--primary-color);
  background-color: var(--background-light);
}

.order-type-options input[type="radio"]:checked + .pill {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select {
  width: 100%;
  padding: var(--spacing-sm);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  background-color: var(--white);
  font-size: var(--font-size-base);
  transition: all 0.2s ease-in-out;
}

.select:hover {
  border-color: var(--primary-color);
}

.select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
}

/* Modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  /*transform: translate(-50%, -50%);*/
  background: var(--white);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-lg);
  /*max-width: 400px;*/
  width: 90%;
  z-index: var(--z-index-modal);
}

.modal-content {
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--border-color);
}

.modal-header h2 {
  margin: 0;
  font-size: var(--font-size-xl);
  color: var(--text-dark);
}

.modal-body {
  padding: var(--spacing-md) 0;
}

.close-button {
  background: none;
  border: none;
  font-size: var(--font-size-xl);
  color: var(--text-light);
  cursor: pointer;
  padding: var(--spacing-xs);
  transition: color var(--transition-fast);
}

.close-button:hover {
  color: var(--text-dark);
}

/* Items table styles */
.items-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--spacing-md);
}

.items-table th,
.items-table td {
  padding: var(--spacing-sm);
  text-align: left;
  border: 1px solid var(--border-color);
}

.items-table th {
  background-color: var(--background-light);
  font-weight: 500;
}

.items-table tr:nth-child(even) {
  background-color: var(--background-light);
}

/* Document upload styles */
.document-upload {
  border: 2px dashed var(--border-color);
  padding: var(--spacing-lg);
  text-align: center;
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-md);
  cursor: pointer;
  transition: border-color var(--transition-fast);
}

.document-upload:hover {
  border-color: var(--primary-color);
}

.document-upload input[type="file"] {
  display: none;
}

.uploaded-file {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background-color: var(--background-light);
  border-radius: var(--border-radius-sm);
  margin-top: var(--spacing-sm);
}

.add-order-modal {
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.items-section table {
  width: 100%;
  border-collapse: collapse;
  margin: var(--spacing-md) 0;
}

.items-section th,
.items-section td {
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  text-align: left;
}

.items-section th {
  background-color: var(--background-light);
  font-weight: 500;
}

.file-upload {
  border: 2px dashed var(--border-color);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  text-align: center;
}

.file-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-sm);
  padding: var(--spacing-sm);
  background-color: var(--background-light);
  border-radius: var(--border-radius-sm);
}

.confirmation-modal {
  position: fixed;
  /*top: 50%;
  left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  background: var(--white);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-lg);
  max-width: 400px;
  width: 90%;
  z-index: var(--z-index-modal);
}

.confirmation-modal h3 {
  margin: 0 0 var(--spacing-md);
  color: var(--text-color);
}

.confirmation-modal p {
  margin: 0 0 var(--spacing-lg);
  color: var(--text-light);
}

.notice {
  padding: var(--spacing-sm);
  background-color: var(--background-light);
  border-radius: var(--border-radius-sm);
  color: var(--text-light);
  text-align: center;
  margin: 0;
}

/* Company selection modal styles */
.company-list {
  max-height: 300px;
  overflow-y: auto;
}

.company-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.company-item:hover {
  background-color: #f8f9fa;
}

.company-item h5 {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.company-item p {
  margin: 5px 0 0;
  font-size: 0.875rem;
  color: #666;
}

.selected-company-details {
  margin-top: 15px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.selected-company-details p {
  margin: 5px 0;
}

@media (min-width: 768px) {
  .form-group {
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-md);
  }

  .form-group label {
    flex: 0 0 200px;
    margin-bottom: 0;
  }

  .form-group .input,
  .form-group .select,
  .form-group textarea,
  .form-group .dropdown {
    flex: 1;
  }
}

@media (max-width: 767px) {
  .form-group {
    flex-direction: column;
    gap: var(--spacing-xs);
  }

  .form-group label {
    margin-bottom: var(--spacing-xs);
  }

  .form-group .input,
  .form-group .select,
  .form-group textarea,
  .form-group .dropdown {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form-group {
    min-width: 100%;
  }
  
  .step-progress {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .step-indicator {
    margin: 0;
  }
  
  .items-section {
    overflow-x: auto;
  }
}
