.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-container {
  width: 70%; /* Adjust as needed */
  max-width: 800px; /* Max width for larger screens */
  max-height: 70%;
  background-color: white;
  position: relative; /* Needed for positioning the close button */
  border-radius: 15px; /* Rounded corners for the modal container */
  padding: 2rem;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Prevent horizontal scrolling */
  padding-bottom: 150px;
  padding-right: 50px;
}

.modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem; /* Larger close button */
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-input,
.modal-textarea {
  width: 60%; /* Limit the width of text fields */
  box-sizing: border-box; /* Ensures padding/border are included in the width */
  font-size: 1rem; /* Increase text size */
}

.modal-textarea {
  height: 150px; /* Makes the description textarea taller */
}

.button-group {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem; /* Space above the button group */
}

.modal-save-button,
.modal-delete-button,
.modal-toggle-status-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem; /* Increase button text size */
}

.modal-save-button {
  background-color: #007bff;
}

.modal-delete-button {
  background-color: #dc3545; /* Red for delete */
}

.modal-toggle-status-button {
  padding: 0.5rem 1rem;
}

.modal-toggle-status-button.active {
  background-color: #28a745; /* Green for active */
}

.modal-toggle-status-button.inactive {
  background-color: #dc3545; /* Red for inactive */
}

.modal-save-button:hover,
.modal-delete-button:hover,
.modal-toggle-status-button:hover {
  opacity: 0.9;
}

.company-info {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.company-info h2 {
  margin-bottom: 0.5rem;
}

.company-info p {
  margin: 0.5rem 0;
}

.company-info a {
  color: #007bff;
  text-decoration: none;
}

.company-info a:hover {
  text-decoration: underline;
}

.react-datepicker {
  z-index: 9999; /* Ensure the date picker is on top of other elements */
}

.react-datepicker__triangle {
  display: none; /* Hide the triangle if you don't want it to show */
}

.react-datepicker-popper {
  z-index: 9999; /* Ensure the popper is on top */
}

.react-datepicker__header {
  background-color: #f5f5f5; /* Example: Header background color */
}

.react-datepicker__day-names {
  font-weight: bold; /* Example: Make day names bold */
}

.event-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  min-width: 400px;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1000;
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .modal-content-container {
    width:100%;
    max-width: 100%;
    max-height: 90%;
    padding-bottom: 50px;
    padding-right: 10px;
  }

  .modal-input,
  .modal-textarea {
    width: 100%; /* Full width on small screens */
  }

  .button-group {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Make buttons full width */
  }

  .modal-save-button,
  .modal-delete-button,
  .modal-toggle-status-button {
    width: 100%; /* Full width buttons on mobile */
  }
}
