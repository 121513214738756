.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.edit-button.editing .icon {
  animation: spin 0.5s linear;
}

.button-text {
  margin-left: 8px;
  transition: opacity 0.3s;
}

.edit-button.editing .button-text {
  opacity: 0;
}

.edit-button .icon {
  font-size: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this to your CSS file */

.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #5bc0de; /* Lighter version of #17a2b8 */
  transition: color 0.3s ease;
}

.edit-button.active {
  color: #17a2b8; /* Accent color */
}

.edit-button.active .edit-icon {
  display: none;
}

.edit-button.save .save-icon {
  display: inline;
}

.save-icon {
  display: none;
}

.dashboard-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
}

.dashboard-section {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  width: 100%;
  overflow: hidden;
}

.section-header {
  background: linear-gradient(to bottom, #f7f7f7, #e6e6e6);
  border-bottom: 1px solid #d1d1d1;
  padding: 15px 20px;
  cursor: pointer;
  user-select: none;
}

.section-header h2 {
  margin: 0;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-header svg {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.section-content {
  padding: 20px;
  background-color: #fff;
  transition: height 0.3s ease;
}

.widget-container {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  height: 100%;
  min-height: 300px;
  flex: 1;
}

/* Analytics Section Styling */
.analytics-filters {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  flex-shrink: 0;
  max-height: 80vh;
  overflow-y: auto;
}

.analytics-graphs {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 0; /* Prevents flex item from overflowing */
}

.filter-group {
  margin-bottom: 20px;
  background: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filter-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 500;
}

.filter-header svg {
  width: 16px;
  height: 16px;
  color: #666;
}

.filter-group label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 14px;
}

.filter-group select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.filter-group select:hover {
  border-color: #aaa;
}

.filter-group select:focus {
  border-color: #4a90e2;
  outline: none;
}

.filter-group select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.custom-date-range {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.custom-date-range input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Graph Container Styling */
.graph-container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* Responsive Grid Layout Overrides */
.react-grid-layout {
  transition: height 0.3s ease !important;
}

.react-grid-item {
  transition: all 0.3s ease !important;
}

/* Make graphs responsive */
.recharts-wrapper {
  width: 100% !important;
}

.recharts-surface {
  width: 100% !important;
}

/* Scrollbar styling */
.analytics-filters::-webkit-scrollbar {
  width: 8px;
}

.analytics-filters::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.analytics-filters::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.analytics-filters::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.development-warning {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.development-warning svg {
  flex-shrink: 0;
  font-size: 20px;
}