/* Ensure the company list items have a fixed width and don't cause layout shifts */
.company-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.company-list li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.company-list li:hover {
  background-color: #f0f0f0;
}