/* Existing CSS Styles */
.form-container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.modal {
  padding-bottom: 100px;
}

.input {
  flex: 1;
  margin-right: 8px; /* Space between input and dropdown */
}

.extra-space {
  padding-top: 100px;
  width: 100%;
}

.extra-space button {
  width: 100%;
}

.emoji-select {
  flex: 0.5; /* Adjust size as needed */
}

.input-dropdown {
  width: 100%;
}
.form-group{
  background-color: white;
}
/* New CSS Styles */
@import './base.css';

.company-page {
  display: flex;
  gap: 20px;
  height: calc(100vh - 75px);
  padding: var(--spacing-md);
  background-color: var(--background-color);
}

.filters-section {
  position: sticky;
  top: 0;
  width: 20vw;
  min-width: 280px;
  flex: 0 0 280px;
  height: calc(100vh - 40px);
  overflow-y: auto;
  background: var(--white);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
}

.companies-list {
  width: 20vw;
  min-width: 280px;
  background: transparent;
  padding: 20px;
  border-radius: var(--border-radius-md);
  overflow-y: auto;
}

.company-details {
  flex: 0 0 calc(100vw - 50vw);
  background: var(--white);
  padding: 20px;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  overflow-y: auto;
  height: calc(100vh - 40px);
}

.company-detail {
  display: flex;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: var(--white);
}

.company-detail strong {
  flex: 0 0 120px;
  color: var(--text-light);
  padding-right: var(--spacing-sm);
  border-right: 1px solid var(--border-color);
}

.company-detail span,
.company-detail a {
  flex: 1;
  min-width: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding-left: var(--spacing-sm);
}

.company-detail a {
  color: var(--primary-color);
  text-decoration: none;
}

.company-detail a:hover {
  text-decoration: underline;
}

.company-details h3,
.company-details p,
.company-details a {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}

.company-details > * {
  width: 100%;
  max-width: 100%;
}

.search-section {
  margin-bottom: var(--spacing-lg);
}

.search-input {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-md);
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.actions-section {
  margin-bottom: var(--spacing-lg);
}

.actions-section button {
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.actions-section button:hover {
  background-color: var(--primary-hover);
}

.companies-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  width: 100%;
}

.company-card {
  width: 100%;
  background: var(--white);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  cursor: pointer;
  transition: transform var(--transition-fast), box-shadow var(--transition-fast);
  border: 1px solid var(--border-color);
}

.company-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.company-card.selected {
  border: 2px solid var(--primary-color);
}

.company-card h3 {
  margin: 0 0 var(--spacing-sm) 0;
  color: var(--text-color);
}

.company-card p {
  margin: var(--spacing-xs) 0;
  color: var(--text-light);
}

.no-items {
  padding: var(--spacing-md);
  text-align: center;
  color: var(--text-light);
  font-size: var(--font-size-sm);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  margin: var(--spacing-md) 0;
}

.no-selection {
  text-align: center;
  color: var(--text-light);
  padding: var(--spacing-lg);
}

.edit-button {
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.edit-button:hover {
  background-color: var(--primary-hover);
}

.filter-type-button {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid var(--border-color);
  background: var(--white);
  text-align: left;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-fast);
  margin-bottom: var(--spacing-sm);
  color: var(--text-color);
}

.filter-type-button:hover {
  background-color: var(--background-light);
  border-color: var(--primary-color);
}

.filter-type-button.selected {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
}

.filter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-sm);
  transition: all var(--transition-fast);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-xs);
}

.filter-item:hover {
  transform: translateX(2px);
  box-shadow: var(--shadow-sm);
}

.filter-actions {
  display: flex;
  gap: var(--spacing-sm);
}

.filter-button {
  padding: var(--spacing-sm) var(--spacing-md);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.add-button {
  background-color: var(--primary-color);
  color: var(--white);
}

.add-button:hover {
  background-color: var(--primary-hover);
}

.edit-button {
  background-color: var(--secondary-color);
  color: var(--white);
}

.edit-button:hover {
  background-color: var(--primary-hover);
}

.delete-button {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
  font-size: var(--font-size-sm);
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.filter-management {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: var(--spacing-md);
  height: 100%;
  overflow: hidden;
  padding: var(--spacing-md);
  background-color: var(--background-light);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
}

.filter-type-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-xs);
}

.filter-content {
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-xs);
  overflow-y: auto;
}

.filter-type-button {
  padding: var(--spacing-sm) var(--spacing-md);
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: var(--background-light);
  color: var(--text-color);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.filter-type-button:hover {
  background-color: var(--primary-color-light);
}

.filter-type-button.active {
  background-color: var(--primary-color);
  color: var(--white);
}

.filter-items-container {
  background-color: var(--background-light);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-md);
}

.filter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--white);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-sm);
  transition: all var(--transition-fast);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-xs);
}

.filter-item:hover {
  transform: translateX(2px);
  box-shadow: var(--shadow-sm);
}

.filter-actions {
  display: flex;
  gap: var(--spacing-sm);
}

.filter-button {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
  font-size: var(--font-size-sm);
}

.edit-button {
  background-color: var(--primary-color);
  color: var(--white);
}

.edit-button:hover {
  background-color: var(--primary-color-dark);
}

.delete-button {
  background-color: #dc3545;
  color: var(--white);
}

.delete-button:hover {
  background-color: #c82333;
}

.filter-add-form {
  background-color: var(--white);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-xs);
}

.no-items {
  padding: var(--spacing-md);
  text-align: center;
  color: var(--text-light);
  background-color: var(--background-lighter);
  border-radius: var(--border-radius-sm);
  font-style: italic;
}

.country-cities-group {
  margin-bottom: var(--spacing-lg);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.country-cities-group h3 {
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-xs);
  border-bottom: 2px solid var(--accent-color);
  color: var(--text-color);
}

.filter-add-form {
  flex-shrink: 0;
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-md);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
}

.filter-input-group {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);
}

.filter-input {
  flex: 1;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
}

.filter-input:focus {
  border-color: var(--primary-color);
  outline: none;
}

.country-select-wrapper {
  margin-bottom: var(--spacing-sm);
}

.country-select-wrapper .select__control,
.emoji-select .select__control {
  border-color: var(--border-color);
  border-radius: var(--border-radius-sm);
  min-height: 38px;
}

.country-select-wrapper .select__control:hover,
.emoji-select .select__control:hover {
  border-color: var(--primary-color);
}

.country-select-wrapper .select__control--is-focused,
.emoji-select .select__control--is-focused {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.emoji-select {
  width: 120px;
  flex-shrink: 0;
}

.selected-filter-items {
  margin-top: var(--spacing-lg);
}

.filter-input-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.filter-input {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.emoji-select {
  min-width: 150px;
  margin-right: 10px;
}

.emoji-select .react-select__control {
  min-height: 38px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.emoji-select .react-select__option {
  padding: 8px 12px;
  cursor: pointer;
}

.emoji-select .react-select__single-value {
  font-size: 16px;
}

.filter-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  overflow: hidden;
}

.filter-items {
  flex: 1;
  overflow-y: auto;
  padding-right: var(--spacing-sm);
}

.company-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.edit-button,
.delete-button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-button {
  background-color: var(--primary-color);
  color: white;
}

.edit-button:hover {
  background-color: var(--primary-hover);
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .company-page {
    flex-direction: column;
    height: auto;
    padding: var(--spacing-sm);
  }

  .filters-section {
    position: relative;
    width: 100%;
    height: auto;
    max-height: none;
    margin-bottom: var(--spacing-md);
  }

  .companies-list {
    width: 100%;
    padding: 4px;
    overflow-x: hidden;
    height: fit-content;
  }

  .companies-grid {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 8px;
    padding: 4px 0;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    height: fit-content;
  }

  .company-card {
    flex: 0 0 55px;
    min-width: 55px;
    margin-right: 0;
    scroll-snap-align: start;
    padding: 8px;
    height: fit-content;
  }

  .company-card h3 {
    font-size: 14px;
    margin: 0 0 4px 0;
  }

  .company-card p {
    font-size: 12px;
    margin: 2px 0;
  }

  .filter-management {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
    height: auto;
  }

  .filter-type-list {
    position: relative;
    height: auto;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: var(--spacing-md);
  }

  .filter-type-options {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: var(--spacing-sm);
    gap: var(--spacing-sm);
  }

  .filter-type-button {
    flex: 0 0 auto;
    width: auto;
    white-space: nowrap;
  }

  .filter-details {
    height: auto;
    max-height: none;
  }

  .filter-input-group {
    flex-direction: column;
  }

  .emoji-select {
    width: 100%;
    margin-right: 0;
    margin-bottom: var(--spacing-sm);
  }

  .company-card {
    flex: 0 0 40%;
    margin-right: var(--spacing-sm);
    height: auto;
  }

  .companies-grid::-webkit-scrollbar {
    height: 4px;
  }

  .companies-grid::-webkit-scrollbar-track {
    background: var(--background-light);
    border-radius: 4px;
  }

  .companies-grid::-webkit-scrollbar-thumb {
    background: var(--accent-color);
    border-radius: 4px;
  }

  .filter-items {
    margin-top: var(--spacing-md);
  }

  .filter-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-sm);
  }

  .filter-item .delete-button {
    padding: 4px 8px;
    font-size: 14px;
  }

  .company-actions {
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }

  .edit-button,
  .delete-button {
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
  }

  .company-details {
    height: auto;
    max-height: none;
    width: 100%;
    flex-basis: content;
  }

  .company-detail {
    flex-direction: column;
    gap: 4px;
    padding: 8px 0;
  }

  .company-detail strong {
    flex: none;
    font-size: 12px;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    padding: 0 0 4px 0;
    margin-bottom: 4px;
  }

  .company-detail span,
  .company-detail a {
    font-size: 14px;
    padding-left: 0;
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .company-page {
    flex-direction: column;
  }

  .filters-section,
  .companies-list,
  .company-details {
    width: 100%;
  }

  .filter-management {
    grid-template-columns: 200px 1fr;
  }
}