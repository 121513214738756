.table-section {
  margin-bottom: var(--spacing-xl);
}

.table-actions {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
}

.add-order-button {
  color: var(--white);
  border: none;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  transition: background-color var(--transition-fast);
}

.add-order-button:hover {
  background-color: var(--primary-hover);
}

.manufacturer-input {
  background-color: var(--background-light);
  color: var(--text-color);
  cursor: not-allowed;
}
